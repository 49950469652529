<template>
  <div>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="soa_company_id"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="SOA Company ID"
        >
          <b-form-input
            v-model="soa_company_id"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="soa_company_id"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="magento_supplier_id"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="Magento Supplier ID"
        >
          <b-form-input
            v-model="magento_supplier_id"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="magento_supplier_id"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="magento_spider_id"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="Magento Spider ID"
        >
          <b-form-input
            v-model="magento_spider_id"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="magento_spider_id"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="magento_spider_name"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="Magento Spider Name"
        >
          <b-form-input
            v-model="magento_spider_name"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="magento_spider_name"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="pos"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="Recommand when POS Discount under"
        >
          <b-form-input
            v-model="pos"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="pos"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="tax_rate"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="Tax Rate"
        >
          <b-form-input
            v-model="tax_rate"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="tax_rate"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="12">
        <b-nav tabs>
          <b-nav-item disabled>Purchase Conditions</b-nav-item>
        </b-nav>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="te_purchase_mode"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="TE Purchase Mode"
        >
          <b-form-input
            v-model="te_purchase_mode"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="te_purchase_mode"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6" class="my-1" v-if="te_purchase_mode == 1">
        <b-form-group
          label-for="te_commission_based_on"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="TE Commission based on"
        >
          <b-form-input
            v-model="te_commission_based_on"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="te_commission_based_on"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6" class="my-1" v-else>
        <b-form-group
          label-for="te_discount_based_on"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="TE Discount based on"
        >
          <b-form-input
            v-model="te_discount_based_on"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="te_discount_based_on"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="my-1" v-if="te_purchase_mode == 1">
        <b-form-group
          label-for="te_commission"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="TE Commission %"
        >
          <b-form-input
            v-model="te_commission"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="te_commission"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6" class="my-1" v-else>
        <b-form-group
          label-for="te_discount"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="TE Discount %"
        >
          <b-form-input
            v-model="te_discount"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="te_discount"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="bulk_purchase"
          label-cols-sm="4"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="Bulk Purchase"
        >
          <b-form-input
            v-model="bulk_purchase"
            type="text"
            :placeholder="this.$t('page.please_choose_input')"
            id="bulk_purchase"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'detail',
  components: {},
  data() {
    return {
      soa_company_id: 11,
      magento_supplier_id: 22,
      magento_spider_id: 33,
      magento_spider_name: 44,
      pos: 55,
      tax_rate: 66,
      te_purchase_mode: 1,
      te_commission_based_on: 1,
      te_discount_based_on: 2,
      te_commission: 3,
      te_discount: 4,
      bulk_purchase: '22'
    };
  },

  methods: {
    init() {
      console.log(this.$route);
      //   this.id = this.$route.params.id;
    }
  },

  computed: {
    // ...mapState({
    //   baseConfig: (state) => state.baseconfig.baseconfig
    // })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
